import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-gray-100 py-4">
      <div className="container mx-auto text-center">
        <div className="flex justify-center space-x-6">
          <a href="https://facebook.com" target="_blank" rel="noreferrer">
            <img src="facebook-icon-url" alt="Facebook" className="h-6" />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noreferrer">
            <img src="instagram-icon-url" alt="Instagram" className="h-6" />
          </a>
          <a href="https://maps.google.com" target="_blank" rel="noreferrer">
            <img src="google-maps-icon-url" alt="Google Maps" className="h-6" />
          </a>
        </div>
        <p className="mt-4 text-sm text-gray-500">© 2023 Pensjonat Zdrojowy. All rights reserved.</p>
      </div>
    </footer>
  );
}
