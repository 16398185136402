// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LoadingProvider } from './contexts/LoadingContext';
import Spinner from './components/Spinner';
import MainLayout from './layouts/MainLayout';
import NoNavbarLayout from './layouts/NoNavbarLayout'; // Jeśli masz taki komponent
import Slider from './components/Slider';
import ReservationBar from './components/ReservationBar';
import ZigzagSection from './components/ZigZagSection';
import RoomSlider from './components/RoomSlider';
import PriceList from './components/PriceList';
import AllRoomsAnimated from './components/AllRoomsAnimated';
import ReservationPage from './components/ReservationPage';
import AllRooms from './components/AllRooms';
import RoomDetail from './components/RoomDetail';
import { SubpageLoadingProvider } from './contexts/SubpageLoadingContext';
import SubpageSpinner from './components/SubpageSpinner';
import ToBeContinued from './components/ToBeContinued';
// Inne importy...

function App() {
  return (
    <LoadingProvider>
      <Router>
        <Spinner />
        <Routes>
          <Route
            path="/"
            element={
              <MainLayout>
                <Slider />
                <ReservationBar />
                <ZigzagSection />
                <RoomSlider />
                <ZigzagSection />
                {/* Tu będziemy dodawać kolejne sekcje */}
              </MainLayout>
            }
          />
          <Route
            path="/pokoje"
            element={
              <NoNavbarLayout>
                <AllRoomsAnimated />
              </NoNavbarLayout>
            }
          />
          <Route
            path="/galeria"
            element={
              <MainLayout>
                <ToBeContinued />
              </MainLayout>
            }
          />
          <Route
            path="/atrakcje-okolicy"
            element={
              <MainLayout>
                <ToBeContinued />
              </MainLayout>
            }
          />
          <Route
            path="/strefa-relaksu"
            element={
              <MainLayout>
                <ToBeContinued />
              </MainLayout>
            }
          />
          <Route
            path="/oferty-specjalne"
            element={
              <MainLayout>
                <ToBeContinued />
              </MainLayout>
            }
          />
          <Route
            path="/kontakt"
            element={
              <MainLayout>
                <ToBeContinued />
              </MainLayout>
            }
          />
          <Route
            path="/rezerwacja"
            element={
              <MainLayout>
                <ReservationPage />
              </MainLayout>
            }
          />
          <Route
            path="/cennik"
            element={
              <MainLayout>
                <SubpageLoadingProvider>
                    <SubpageSpinner />
                    <PriceList />
                </SubpageLoadingProvider>
              </MainLayout>
            }
          />
          <Route
            path="/wszystkie-pokoje"
            element={
              <MainLayout>
                <AllRooms />
              </MainLayout>
            }
          />
          <Route
            path="/pokoje/:slug"
            element={
              <MainLayout>
                <RoomDetail />
              </MainLayout>
            }
          />
          {/* Inne trasy... */}
        </Routes>
      </Router>
    </LoadingProvider>
  );
}

export default App;
