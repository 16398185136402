// import React, { useState, useEffect, useRef } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/effect-coverflow';
// import './RoomSlider.css';
// import { Navigation, Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';
// import { useLoading } from '../contexts/LoadingContext';

// const RoomSlider = () => {
//     const [featuredRooms, setFeaturedRooms] = useState([]);
//     const swiperRef = useRef(null);
//     const { setIsLoading } = useLoading();

//     useEffect(() => {
//         setIsLoading(true);
//         fetch('https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/pokoje?acf_format=standard')
//             .then(response => response.json())
//             .then(data => {
//                 const featured = data.filter(room => room.acf.slider === true);
//                 setFeaturedRooms(featured.reverse());
//             })
//             .catch(error => console.error('Error fetching featured rooms:', error))
//             .finally(() => setIsLoading(false));
//     }, [setIsLoading]);

//     useEffect(() => {
//         if (swiperRef.current && swiperRef.current.swiper && featuredRooms.length > 0) {
//             // Ręczne uruchomienie autoplay po załadowaniu danych
//             swiperRef.current.swiper.autoplay.start();
//         }
//     }, [featuredRooms]);

//     return (   
//         <>
//             <div className='relative h-[100px] w-full bg-[radial-gradient(circle,rgba(41,63,86,1)_27%,rgba(38,101,130,1)_90%,rgba(27,113,153,1)_100%)]'>
//                 <div className="custom-shape-divider-top-1728480154">
//                     <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
//                         <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
//                     </svg>
//                 </div>
//             </div>
//             <div className="slider-container bg-[radial-gradient(circle,rgba(41,63,86,1)_27%,rgba(38,101,130,1)_90%,rgba(27,113,153,1)_100%)] h-full w-full py-8">
//                 {featuredRooms.length > 0 && (
//                     <Swiper
//                         ref={swiperRef}
//                         effect={'coverflow'}
//                         centeredSlides={true}
//                         slidesPerView={2}
//                         spaceBetween={45}
//                         grabCursor={true}
//                         loop={true}
//                         autoplay={{
//                             delay: 4000,
//                             disableOnInteraction: false,
//                             waitForTransition: false,
//                         }}
//                         coverflowEffect={{
//                             rotate: 0,
//                             stretch: 0,
//                             depth: 200,
//                             slideShadows: false,
//                             modifier: 1,
//                         }}
//                         pagination={{
//                             clickable: true,
//                         }}
//                         navigation={true}
//                         modules={[Navigation, Pagination, Autoplay, EffectCoverflow]}
//                         breakpoints={{
//                             640: {
//                                 slidesPerView: 1.5,
//                             },
//                             1024: {
//                                 slidesPerView: 3,
//                             },
//                             1440: {
//                                 slidesPerView: 2,
//                             },
//                         }}
//                     >
//                         {featuredRooms.map((room, index) => (
//                             <SwiperSlide key={index}>
//                                 <div className="room-card h-full w-full relative flex flex-col items-center rounded-lg overflow-hidden">
//                                     <img
//                                         src={room.acf.image}
//                                         alt={room.title.rendered}
//                                         className="room-image object-cover rounded-lg w-full h-full"
//                                     />
//                                     <div className="absolute bottom-5 left-5 text-white bg-opacity-75 bg-black p-4 rounded-md">
//                                         <h3 className="room-title text-lg font-bold">{room.title.rendered}</h3>
//                                         <p className="room-price text-sm">od {room.acf.price} zł/noc</p>
//                                         <p className="room-info text-sm">Ilość osób: max {room.acf.max_guests}</p>
//                                         <p className="room-info text-sm">Powierzchnia: {room.acf.area} m²</p>
//                                     </div>
//                                 </div>
//                             </SwiperSlide>
//                         ))}
//                     </Swiper>
//                 )}
//             </div>
//             <div className='relative h-[100px] w-full bg-[radial-gradient(circle,rgba(41,63,86,1)_27%,rgba(38,101,130,1)_90%,rgba(27,113,153,1)_100%)]'>
//                 <div className="custom-shape-divider-bottom-1728480249">
//                     <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
//                         <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
//                     </svg>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default RoomSlider;






// // import React, { useState, useEffect } from 'react';
// // import { Swiper, SwiperSlide } from 'swiper/react';
// // import 'swiper/css';
// // import 'swiper/css/navigation';
// // import 'swiper/css/pagination';
// // import 'swiper/css/effect-coverflow'; // Dodanie coverflow
// // import './RoomSlider.css';
// // import { Navigation, Pagination, Autoplay, EffectCoverflow } from 'swiper/modules'; 

// // const RoomSlider = () => {
// //     const [featuredRooms, setFeaturedRooms] = useState([]);

// //     useEffect(() => {
// //         fetch('https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/pokoje?acf_format=standard')
// //             .then(response => response.json())
// //             .then(data => {
// //                 const featured = data.filter(room => room.acf.slider === true); 
// //                 setFeaturedRooms(featured.reverse());
// //             })
// //             .catch(error => console.error('Error fetching featured rooms:', error));
// //     }, []);

// //     return (
// //         <div className="slider-container h-full w-full my-10 py-8">
// //             <Swiper
// //                 effect={'coverflow'}
// //                 enabled={true}
// //                 centeredSlides={true}
// //                 slidesPerView={2} // Zaczynamy od jednej karty na małych ekranach
// //                 spaceBetween={45}
// //                 grabCursor={true}
// //                 loop={true}
// //                 observer={true}
// //                 init={true}
// //                 initialSlide={0}
// //                 onAny={{
// //                 }}
// //                 autoplay={{
// //                     delay: 2000,
// //                     disableOnInteraction: false,
// //                 }}
// //                 coverflowEffect={{
// //                     rotate: 0,
// //                     stretch: 0,
// //                     depth: 200,
// //                     slideShadows: false,
// //                     modifier: 1,
// //                 }}
// //                 pagination={{ 
// //                     clickable: true,
// //                 }}
// //                 navigation={true}
// //                 modules={[Navigation, Pagination, Autoplay, EffectCoverflow]}
// //                 breakpoints={{
// //                     640: {
// //                         slidesPerView: 1.5,
// //                     },
// //                     1024: {
// //                         slidesPerView: 3,
// //                     },
// //                     1440: {
// //                         slidesPerView: 2,
// //                     },
// //                 }}
// //             >
// //                 {featuredRooms.map((room, index) => (
// //                     <SwiperSlide key={index}>
// //                         <div className="room-card h-full w-full relative flex flex-col items-center rounded-lg overflow-hidden">
// //                             <img
// //                                 src={room.acf.image}
// //                                 alt={room.title.rendered}
// //                                 className="room-image object-cover rounded-lg w-full h-full"
// //                             />
// //                             <div className="absolute bottom-5 left-5 text-white bg-opacity-75 bg-black p-4 rounded-md">
// //                                 <h3 className="room-title text-lg font-bold">{room.title.rendered}</h3>
// //                                 <p className="room-price text-sm">od {room.acf.price} zł/noc</p>
// //                                 <p className="room-info text-sm">Ilość osób: max {room.acf.max_guests}</p>
// //                                 <p className="room-info text-sm">Powierzchnia: {room.acf.area} m²</p>
// //                             </div>
// //                         </div>
// //                     </SwiperSlide>
// //                 ))}
// //             </Swiper>
// //         </div>
       
// //     );
// // };

// // export default RoomSlider;



import React, { useState, useEffect, useRef, useContext } from 'react';
import LoadingContext from '../contexts/LoadingContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import './RoomSlider.css';
import { Navigation, Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';

const RoomSlider = () => {
    const { startLoading, finishLoading } = useContext(LoadingContext);
    const [featuredRooms, setFeaturedRooms] = useState([]);
    const swiperRef = useRef(null);

    useEffect(() => {
        startLoading('RoomSlider');
        fetch('https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/pokoje?acf_format=standard')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                const featured = data.filter(room => room.acf.slider === true);
                setFeaturedRooms(featured.reverse());
                finishLoading('RoomSlider');
            })
            .catch((error) => {
                console.error('Error fetching featured rooms:', error);
                finishLoading('RoomSlider');
            });
    }, [startLoading, finishLoading]);

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper && featuredRooms.length > 0) {
            // Ręczne uruchomienie autoplay po załadowaniu danych
            swiperRef.current.swiper.autoplay.start();
        }
    }, [featuredRooms]);

    return (
        <>
            <div className='relative h-[100px] w-full bg-[radial-gradient(circle,rgba(41,63,86,1)_27%,rgba(38,101,130,1)_90%,rgba(27,113,153,1)_100%)]'>
                <div className="custom-shape-divider-top-1728480154">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
            </div>
            <div className="slider-container bg-[radial-gradient(circle,rgba(41,63,86,1)_27%,rgba(38,101,130,1)_90%,rgba(27,113,153,1)_100%)] h-full w-full py-8">
                {featuredRooms.length > 0 && (
                    <Swiper
                        ref={swiperRef}
                        effect={'coverflow'}
                        centeredSlides={true}
                        slidesPerView={2}
                        spaceBetween={45}
                        grabCursor={true}
                        loop={true}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                            waitForTransition: false,
                        }}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 200,
                            slideShadows: false,
                            modifier: 1,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Navigation, Pagination, Autoplay, EffectCoverflow]}
                        breakpoints={{
                            640: {
                                slidesPerView: 1.5,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                            1440: {
                                slidesPerView: 2,
                            },
                        }}
                    >
                        {featuredRooms.map((room, index) => (
                            <SwiperSlide key={index}>
                                <div className="room-card h-full w-full relative flex flex-col items-center rounded-lg overflow-hidden">
                                    <img
                                        src={room.acf.image}
                                        alt={room.title.rendered}
                                        className="room-image object-cover rounded-lg w-full h-full"
                                    />
                                    <div className="absolute bottom-5 left-5 text-white bg-opacity-75 bg-black p-4 rounded-md">
                                        <h3 className="room-title text-lg font-bold">{room.title.rendered}</h3>
                                        <p className="room-price text-sm">od {room.acf.price} zł/noc</p>
                                        <p className="room-info text-sm">Ilość osób: max {room.acf.max_guests}</p>
                                        <p className="room-info text-sm">Powierzchnia: {room.acf.area} m²</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </div>
            <div className='relative h-[100px] w-full bg-[radial-gradient(circle,rgba(41,63,86,1)_27%,rgba(38,101,130,1)_90%,rgba(27,113,153,1)_100%)]'>
                <div className="custom-shape-divider-bottom-1728480249">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
            </div>
        </>
    );
};

export default RoomSlider;
