import React, { useEffect, useState, useRef, useContext } from 'react';
import LoadingContext from '../contexts/LoadingContext';

const Slider = () => {
  const { startLoading, finishLoading } = useContext(LoadingContext);
  const [banners, setBanners] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false); // Dodajemy stan do wykrywania urządzeń mobilnych
  const sliderRef = useRef(null);
  const [slideWidth, setSlideWidth] = useState(0);
  const [slideHeight, setSlideHeight] = useState(0);


  // Funkcja do sprawdzania szerokości ekranu i ustalenia, czy jesteśmy na urządzeniu mobilnym
  useEffect(() => {
    const checkIsMobile = () => {
      const mobileBreakpoint = 768; // Próg dla tabletów i telefonów
      setIsMobile(window.innerWidth <= mobileBreakpoint);
    };

    checkIsMobile(); // Sprawdź przy pierwszym renderowaniu
    window.addEventListener('resize', checkIsMobile); // Aktualizuj przy zmianie rozmiaru okna

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  useEffect(() => {
    startLoading('Slider');
    // Fetch data
    fetch('https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/baner?acf_format=standard')
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const sortedBanners = data.sort((a, b) => a.acf.priority - b.acf.priority);
        setBanners(sortedBanners);
        finishLoading('Slider');
      })
      .catch((error) => {
        console.error('Error fetching banners:', error);
        finishLoading('Slider');
      });
  }, [startLoading, finishLoading]);


  // Autoplay - przesuwanie co 6 sekund
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === banners.length - 1 ? 0 : prevIndex + 1));
    }, 6000); // Zmieniaj slajd co 6 sekund

    return () => clearInterval(interval); // Wyczyść interval po unmount
  }, [banners.length]);

  useEffect(() => {
    const updateSlideDimensions = () => {
      if (sliderRef.current) {
        const width = sliderRef.current.offsetWidth;
        setSlideWidth(width);
        if (isMobile) {
          setSlideHeight(width * 9 / 16); // Ustal proporcje 16:9 dla urządzeń mobilnych
        } else {
          setSlideHeight(width * 0.28125); // Ustal proporcje 1920x540 (3.56:1) dla komputerów
        }
      }
    };

    updateSlideDimensions();
    window.addEventListener('resize', updateSlideDimensions);

    return () => window.removeEventListener('resize', updateSlideDimensions);
  }, [isMobile]);

  const goPrev = () => {
    const newIndex = currentIndex === 0 ? banners.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goNext = () => {
    const newIndex = currentIndex === banners.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div ref={sliderRef} className="relative w-full overflow-hidden" style={{ height: `${slideHeight}px` }}>
      {banners.length > 0 ? (
        <>
          {/* Wrapper na slajdy */}
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{ transform: `translateX(-${currentIndex * slideWidth}px)` }}
          >
            {banners.map((banner, index) => (
              <div
                key={index}
                className="flex-shrink-0"
                style={{
                  width: `${slideWidth}px`,
                  height: `${slideHeight}px`,
                }}
              >
                <a href={banner.acf.url}>
                  <img
                    src={isMobile ? banner.acf.mobile_image : banner.acf.image}
                    alt={banner.title.rendered}
                    className="w-full h-full object-cover"
                  />
                </a>
              </div>
            ))}
          </div>

          {/* Strzałki do nawigacji */}
          {!isMobile && (
            <>
              {/* Strzałka w lewo */}
              <button
                onClick={goPrev}
                className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white border-2 border-primary text-primary hover:bg-accent hover:text-darkBlue px-4 py-2 rounded-[16px] w-12 h-12 flex items-center justify-center"
              >
                <span className='text-[32px] pb-1.5'>&larr;</span>
              </button>

              {/* Strzałka w prawo */}
              <button
                onClick={goNext}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white border-2 border-primary text-primary hover:bg-accent hover:text-darkBlue px-4 py-2 rounded-[16px] w-12 h-12 flex items-center justify-center"
              >
                <span className='text-[32px] pb-1.5'>&rarr;</span>
              </button>
            </>
          )}

          {/* Kropki nawigacyjne */}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-3">
            {banners.map((_, index) => (
              <button
                key={index}
                onClick={() => goToSlide(index)}
                className={`w-5 h-5 rounded-full ${currentIndex === index ? 'bg-blue-500' : 'bg-gray-300'}`} // Większe kropki
              />
            ))}
          </div>
        </>
      ) : (
        null
      )}
    </div>
  );
};

export default Slider;
