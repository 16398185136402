import React, { useState, useEffect  } from 'react'; // useContext
import './AllRoomsAnimated.css'; // Import stylów jeśli nie korzystamy z TailwindCSS
// import LoadingContext from '../contexts/LoadingContext';
import img1 from '../media/img1.jpg';
import img2 from '../media/img2.jpg';
import img3 from '../media/img3.jpg';
import img4 from '../media/img4.jpg';

const AllRoomsAnimated = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const { startLoading, finishLoading } = useContext(LoadingContext);
//   const timeRunning = 3000;
  const timeAutoNext = 5000;

  const items = [
    {
      img: img1,
      author: 'LUNDEV',
      title: 'DESIGN SLIDER',
      topic: 'ANIMAL',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas quis felis suscipit elit dictum congue a vel nisl. Proin quis ligula in arcu aliquam fringilla. Quisque ornare arcu et aliquet tempus. Vivamus posuere sem vehicula sem accumsan varius. Nunc congue neque ut ante tincidunt, quis vestibulum mi suscipit. Nullam eu nunc sit amet lacus porttitor placerat. Curabitur tristique egestas nisl sed congue.',
    },
    {
      img: img2,   
      author: 'LUNDEV',
      title: 'DESIGN SLIDER',
      topic: 'ANIMAL',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas quis felis suscipit elit dictum congue a vel nisl. Proin quis ligula in arcu aliquam fringilla. Quisque ornare arcu et aliquet tempus. Vivamus posuere sem vehicula sem accumsan varius. Nunc congue neque ut ante tincidunt, quis vestibulum mi suscipit. Nullam eu nunc sit amet lacus porttitor placerat. Curabitur tristique egestas nisl sed congue.',
    },
    {
      img: img3,   
      author: 'LUNDEV',
      title: 'DESIGN SLIDER',
      topic: 'ANIMAL',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas quis felis suscipit elit dictum congue a vel nisl. Proin quis ligula in arcu aliquam fringilla. Quisque ornare arcu et aliquet tempus. Vivamus posuere sem vehicula sem accumsan varius. Nunc congue neque ut ante tincidunt, quis vestibulum mi suscipit. Nullam eu nunc sit amet lacus porttitor placerat. Curabitur tristique egestas nisl sed congue.',
    },
    {
      img: img4,  
      author: 'LUNDEV',
      title: 'DESIGN SLIDER',
      topic: 'ANIMAL',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas quis felis suscipit elit dictum congue a vel nisl. Proin quis ligula in arcu aliquam fringilla. Quisque ornare arcu et aliquet tempus. Vivamus posuere sem vehicula sem accumsan varius. Nunc congue neque ut ante tincidunt, quis vestibulum mi suscipit. Nullam eu nunc sit amet lacus porttitor placerat. Curabitur tristique egestas nisl sed congue.',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === items.length - 1 ? 0 : prevIndex + 1
      );
    }, timeAutoNext);

    return () => clearInterval(interval);
  }, [items.length, timeAutoNext]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className='body'>
        <div className="carousel">
          <div className="list">
            {items.map((item, index) => (
              <div
                key={index}
                className={`item transition-opacity duration-1000 ${
                  index === currentIndex ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <img src={item.img} alt={item.title} />
                <div className="content">
                  <div className="author">{item.author}</div>
                  <div className="title">{item.title}</div>
                  <div className="topic">{item.topic}</div>
                  <div className="des">{item.description}</div>
                  <div className="buttons">
                    <button>SEE MORE</button>
                    <button>SUBSCRIBE</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="thumbnail">
            {items.map((item, index) => (
              <div key={index} className={`item ${index === currentIndex ? 'border-4 border-white rounded-3xl' : ''}`}>
                <img src={item.img} alt={item.title} />
                <div className="content">
                  <div className="title">{item.title}</div>
                  <div className="description">{item.topic}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="arrows">
            <button onClick={handlePrev}>‹</button>
            <button onClick={handleNext}>›</button>
          </div>
          <div className="time" style={{ width: `${(currentIndex + 1) * (100 / items.length)}%` }}></div>
        </div>
    </div>
  );
};

export default AllRoomsAnimated;
