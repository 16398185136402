import React, { useState } from 'react';
import Logo from '../media/logo.png';
// import WeatherWidget from './WeatherWidget';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <nav className="text-primaryBlue border-b border-gray-200 px-4 sm:px-6 py-4">
      <div className="flex md:flex-col justify-between items-center">
        <div className="flex-shrink-0">
          <a href="/">
            <img src={Logo} alt="Logo" className="h-16 mb-1" />
          </a>
        </div>
        <div className="items-center hidden md:flex space-x-6">
          <a href="/cennik" className="text-primary hover:text-accent">Cennik</a>
          <a href="/galeria" className="text-primary hover:text-accent">Galeria</a>
          <a href="/atrakcje-okolicy" className="text-primary hover:text-accent">Atrakcje okolicy</a>
          <a href="/strefa-relaksu" className="text-primary hover:text-accent">Strefa Relaksu</a>
          <a href="/pokoje" className="text-primary hover:text-accent">Pokoje</a>
          <a href="/oferty-specjalne" className="text-primary hover:text-accent">Oferty Specjalne</a>
          <a href="/kontakt" className="text-primary hover:text-accent">Kontakt</a>
          <div className='absolute right-10'>
            {/* <WeatherWidget /> */}
          </div>
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-gray-500">
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'} />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden mt-4 space-y-2">
          <a href="/cennik" className="block text-primary hover:text-accent">Cennik</a>
          <a href="/galeria" className="block text-primary hover:text-accent">Galeria</a>
          <a href="/atrakcje-okolicy" className="block text-primary hover:text-accent">Atrakcje okolicy</a>
          <a href="/strefa-relaksu" className="block text-primary hover:text-accent">Strefa Relaksu</a>
          <a href="/pokoje" className="block text-primary hover:text-accent">Pokoje</a>
          <a href="/oferty-specjalne" className="block text-primary hover:text-accent">Oferty Specjalne</a>
          <a href="/kontakt" className="block text-primary hover:text-accent">Kontakt</a>
        </div>
      )}
    </nav>
  );
}
