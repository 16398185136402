import React, { useEffect, useState, useContext } from 'react'; 
import SubpageLoadingContext from '../contexts/SubpageLoadingContext';


const PriceList = () => {
    const { startLoading, finishLoading } = useContext(SubpageLoadingContext);
    const [tables, setTables] = useState([]);


    useEffect(() => {
        startLoading('PriceList');
        fetch('https://test.itneed.pl/WORDPRESS/wp-json/wp/v2/cennik?acf_format=standard')
            .then(response => response.json())
            .then(data => {
                // Przetwarzanie danych
                const processedTables = data.map(table => {
                    // Parsowanie JSON z pola 'wiersze_tabeli_json'
                    const items = JSON.parse(table.acf.wiersze_tabeli_json);
                    return {
                        title: table.acf.title,
                        subtitle: table.acf.subtitle,
                        price: table.acf.price,
                        items: items
                    };
                });
                setTables(processedTables); // Ustawienie stanu po przetworzeniu danych
                finishLoading('PriceList');
            })
            .catch(error => {
                console.error('Error fetching price tables:', error);
                finishLoading('PriceList');
            });
    }, [startLoading, finishLoading]);
    

    if (!tables) {
        return (<div> <h1>dupa</h1> </div>);
      }
    
// bg-[radial-gradient(circle,rgba(27,113,153,1)_51%,rgba(49,76,86,1)_100%)]
    return (
        <div className="price-list max-w-4xl mx-auto mt-10">
            <div>
                <h1 className="text-4xl font-bold text-center mb-8">Cenniki</h1>
            </div>
            {tables.map((table, index) => (
                <div key={index} className="price-table mb-10  shadow-md rounded-lg p-6">
                    <div className="table-header mb-4">
                        <h2 className="text-2xl font-semibold">{table.title}</h2>
                    </div>
                    <div className="table-body">
                        <div className="table-subtitle flex justify-between items-center border-b-2 border-darkBlue pb-2 mb-4">
                            <h3 className="text-xl font-medium">{table.subtitle}</h3>
                            <span className="table-price font-bold text-lg">{table.price}</span>
                        </div>
                        <ul className="table-items divide-y">
                            {table.items.map((item, idx) => (
                                <li key={idx} className="table-item flex justify-between py-2 border-b-0 border-darkBlue">
                                    <span className="font-medium">{item.name}</span>
                                    <span className="font-medium">{item.price}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PriceList;